.pg-viewer-wrapper {
  overflow-y: auto !important;
}

video {
  max-width: 100%;
  max-height: 100%;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 16px;
  margin-top: 16px;
  border-radius: 16px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  max-width: 100%;
  height: auto !important;
  border-radius: 16px;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.react-pdf__Page {
  margin-top: 16px;
}
