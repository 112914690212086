@font-face {
  font-family: "Moderna";
  font-style: "normal";
  font-display: "swap";
  font-weight: "normal";
  src: local("Moderna.ttf"), url("./Moderna.ttf") format("truetype");
}

@font-face {
  font-family: 'Matter';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local("Matter-Light.woff"), url("./Matter-Light.woff") format("woff");
}

@font-face {
  font-family: 'Matter';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local("Matter-Light.woff"), url("./Matter-Light.woff") format("woff");
}

@font-face {
  font-family: 'Matter';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Matter-Light.woff"), url("./Matter-Light.woff") format("woff");
}

@font-face {
  font-family: 'Matter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Matter-Regular.woff"), url("./Matter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Matter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Matter-Medium.woff"), url("./Matter-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Matter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Matter-Medium.woff"), url("./Matter-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Matter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Matter-Bold.woff"), url("./Matter-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Matter';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local("Matter-Bold.woff"), url("./Matter-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Matter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local("Matter-Bold.woff"), url("./Matter-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Domaine-Display';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("domaine-display-regular.woff2"), url("./domaine-display-regular.woff2") format("woff2");
}
