@supports (zoom: 0.85) {
  html {
    zoom: 1;
    overflow: hidden;
    width: 100vw;
  }
}

@supports (height: 100dvh) {
  #releesebody {
    height: 100dvh;
  }
}

@supports not (height: 100dvh) {
  #releesebody {
    height: 100vh;
  }
}
