#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
  color: rgb(95, 46, 234)!important;
  border: 1px solid rgb(95, 46, 234)!important;
  height: auto;
  white-space: normal;
  word-wrap: break-word;
  padding: 0.8em 2em;
  font-size: 0.8em;
  line-height: 1.2;
  cursor: pointer;
  -moz-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  -webkit-transition: 1s ease;
  transition: 0.1s ease;
  font-family: Poppins,sans-serif,Moderna,Helvetica Neue,Droid Sans,Fira Sans,Cantarell,Ubuntu,Inter,Oxygen,Roboto,Segoe UI,BlinkMacSystemFont,-apple-system;
}
