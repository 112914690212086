[data-slate-editor] {
  position: relative;
}

.slate-start-area {
  z-index: -999 !important;
}

.slate-selection-area {
  background: #4f90f22d;
  border: 1px solid #4f90f2;
}

.drag-button {
  overflow: hidden;
  padding: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  border-style: none;
  cursor: pointer;
  outline: 0;

  min-width: 18px;
  min-height: 18px;
}

.slate-td {
  border: 1px solid black;
  background: transparent !important;
}

.slate-td {
  background: transparent;
}

.slate-table>div {
  display: none;
}

.slate-table {
  width: 99% !important;
}
