@keyframes ring {
  0% {
    transform: rotate(35deg);
  }

  6.25% {
    transform: rotate(-30deg);
  }

  12.5% {
    transform: rotate(25deg);
  }

  18.75% {
    transform: rotate(-20deg);
  }

  25% {
    transform: rotate(15deg);
  }

  31.25% {
    transform: rotate(-10deg);
  }

  37.5% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
